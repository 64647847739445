.loader_page{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  .head{
    padding: 36px 64px;
  }
  .shadow{
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wrapper{
    overflow: hidden;
    position: fixed;
    left: calc(50% - 96px);
    top: calc(50% - 82px);
    z-index: 1;
  }
  .loader_img{
    //background: url("../../../assets/image/loading.svg");
    width: 192px;
    height: 164px;
  }
}
@media only screen and (max-width: 1024px) {
  .loader_page {
    .head{
      padding: 36px 48px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .loader_page {
    .head {
      padding: 18px 16px;
        img{
          height: 29px;
          width: 80px;
          background-size: 100% 100%;
        }
    }
  }
}
