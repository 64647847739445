.circle-body {
    background-color: #d3bebd;
}
.row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.title {
    margin-bottom: 70px;
}
.circle-title {
    font-weight: 600;
    color: #4e1414;
    padding-bottom: 20px;
}
#logo {
    width: 30vh;
    position: absolute;
    top: 35px;
    left: 30px;
}
.question-text,
.title {
    font-size: 3em;
    color: #4e1414 !important;
}
.continue {
    background: transparent !important;
    border-radius: 50%;
    padding: 35px 5px;
    font-size: 2em;
    border: 2px solid #4e1414;
    color: #4e1414;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.continue:active,
.continue:hover {
    border-color: #4e4e4e;
    color: #4e4e4e;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
#questions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
#wheel {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.wheel-visible {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}
.wheel-outside {
    background: transparent;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin: 10px auto 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.wheel-inside {
    background-image: url(https://circle.blinovskaya.com/img/circle.png);
    width: 590px;
    height: 590px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.circle-figure {
    background: transparent;
    // width: 78%;
    // height: 78%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.center {
    position: relative;
    background: #ee749e;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50px;
    top: 0px;
    left: 21px;
    z-index: 2;
    @media only screen and (max-width: 1400px) {
        width: 9px;
        height: 9px;
        left: 19.5px;
    }
    @media only screen and (max-width: 767px) {
        width: 5.5px;
        height: 5.5px;
        left: 17.75px;
    }
}
.stroke-container {
    width: 5px;
    height: 230px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    z-index: 1;
    @media only screen and (max-width: 1400px) {
        width: 5px;
        height: 172.5px;
    }
    @media only screen and (max-width: 767px) {
        height: 104px;
    }
}
.stroke {
    background: #f6c9da;
    width: 3px;
    height: 20px;
    display: inline-block;
    @media only screen and (max-width: 1400px) {
        width: 2px;
        height: 11px;
    }
    @media only screen and (max-width: 767px) {
        width: 1.5px;
        height: 6.7px;
    }
}
.dot {
    background: #f6c9da;
    width: 7px;
    height: 7px;
    display: inline-block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: relative;
    @media only screen and (max-width: 1400px) {
        width: 6px;
        height: 6px;
    }
    @media only screen and (max-width: 767px) {
        width: 3.5px !important;
        height: 3.5px !important;
    }
}
.stroke-1 {
    top: -118px;
    left: 12.7px;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    @media only screen and (max-width: 1400px) {
        top: -89px;
        left: 12.7px;
    }
    @media only screen and (max-width: 767px) {
        top: -53px;
        left: 12.7px;
    }
}
.stroke-2 {
    top: -83px;
    left: 90px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    @media only screen and (max-width: 1400px) {
        top: -63px;
        left: 71px;
    }
    @media only screen and (max-width: 767px) {
        top: -38px;
        left: 45px;
    }
}
.stroke-3 {
    top: 0px;
    left: 117px;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    @media only screen and (max-width: 1400px) {
        top: 0px;
        left: 92px;
    }
    @media only screen and (max-width: 767px) {
        top: 0px;
        left: 56px;
    }
}
.stroke-4 {
    top: 81px;
    left: 80px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    @media only screen and (max-width: 1400px) {
        top: 63px;
        left: 60px;
    }
    @media only screen and (max-width: 767px) {
        top: 38px;
        left: 35px;
    }
}
.stroke-5 {
    top: 117px;
    left: -7.3px;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    @media only screen and (max-width: 1400px) {
        top: 87px;
        left: -7.3px;
    }
    @media only screen and (max-width: 767px) {
        top: 54px;
        left: -7.3px;
    }
}
.stroke-6 {
    top: 82px;
    left: -96px;
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    -moz-transform: rotate(225deg);
    -ms-transform: rotate(225deg);
    -o-transform: rotate(225deg);
    @media only screen and (max-width: 1400px) {
        top: 63px;
        left: -75px;
    }
    @media only screen and (max-width: 767px) {
        top: 38px;
        left: -51px;
    }
}

.stroke-7 {
    top: 0px;
    left: -133px;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    @media only screen and (max-width: 1400px) {
        top: 0px;
        left: -104px;
    }
    @media only screen and (max-width: 767px) {
        top: 0px;
        left: -70px;
    }
}
.stroke-8 {
    top: -84px;
    left: -105px;
    transform: rotate(315deg);
    -webkit-transform: rotate(315deg);
    -moz-transform: rotate(315deg);
    -ms-transform: rotate(315deg);
    -o-transform: rotate(315deg);
    @media only screen and (max-width: 1400px) {
        top: -63px;
        left: -85px;
    }
    @media only screen and (max-width: 767px) {
        top: -38px;
        left: -61px;
    }
}
.color-stroke--1 {
    background: #ee749e !important;
}
.color-stroke--2 {
    background: #ee749e !important;
}
.color-stroke--3 {
    background: #ee749e !important;
}
.color-stroke--4 {
    background: #ee749e !important;
}
.color-stroke--5 {
    background: #ee749e !important;
}
.color-stroke--6 {
    background: #ee749e !important;
}
.color-stroke--7 {
    background: #ee749e !important;
}
.color-stroke--8 {
    background: #ee749e !important;
}
.color-dot--1 {
    background: #ee749e !important;
}
.color-dot--2 {
    background: #ee749e !important;
}
.color-dot--3 {
    background: #ee749e !important;
}
.color-dot--4 {
    background: #ee749e !important;
}
.color-dot--5 {
    background: #ee749e !important;
}
.color-dot--6 {
    background: #ee749e !important;
}
.color-dot--7 {
    background: #ee749e !important;
}
.color-dot--8 {
    background: #ee749e !important;
}

.svg {
    width: 460px;
    height: 460px;
    @media only screen and (max-width: 767px) {
        width: 208px;
        height: 208px;
    }
}

.svg-1 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-2 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-3 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-4 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-5 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-6 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-7 {
    stroke: #ee749e;
    stroke-width: 0;
}
.svg-8 {
    stroke: #ee749e;
    stroke-width: 0;
}
.transition-svg-1 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-2 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-3 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-4 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-5 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-6 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-7 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}
.transition-svg-8 {
    stroke-width: 3;
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
    @media only screen and (max-width: 1400px) {
        stroke-width: 2;
    }
    @media only screen and (max-width: 767px) {
        stroke-width: 1;
    }
}

.transition-stroke {
    transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
    -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
    -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
    -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
    -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
}
.transition-dot {
    transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    -webkit-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    -moz-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    -ms-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    -o-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.highlight {
    border: 2px solid #ff0000 !important;
}
:focus {
    outline: none -webkit-focus-ring-color !important;
}
.btn:focus {
    outline: none !important;
    -webkit-box-shadow: 0 0 0 0 transparent !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.svg {
    position: absolute;
    overflow: visible !important;
}
.path {
    position: relative;
}
/*Range Slider Styles*/
.range-slider {
    margin: 10vh auto;
}
.rs-range {
    margin-top: 30px;
    width: 600px;
    -webkit-appearance: none;
}
.rs-range:focus {
    outline: none;
}
.rs-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #fff;
    border-radius: 0px;
    border: 0px solid #4e1414;
}
.rs-range::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #fff;
    border-radius: 0px;
    border: 0px solid #4e1414;
}
.rs-range::-webkit-slider-thumb {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0px solid #fff;
    -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
.rs-range::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #fff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
}
.rs-range::-moz-focus-outer {
    border: 0;
}
.rs-label {
    position: relative;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    display: block;
    width: 60px;
    height: 60px;
    background: transparent;
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    padding-top: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #4e1414;
    margin-top: 0;
    margin-left: -10px;
    left: attr(value);
    color: #4e1414;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    font-size: 2.5em;
}
/*Range Slider Styles*/
/* @media all and (max-width: 374px) {
    .h4,
    h4 {
        font-size: 1.6rem !important;
    }
    .h3,
    h3 {
        font-size: 1.8rem !important;
    }
    .h3 {
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 0 10px;
    }
    #logo {
        left: auto;
        right: auto;
    }
    #logo-bottom {
        display: none !important;
    }
    .logo-mobile {
        width: 110px;
        display: block;
    }
    .h2,
    h2 {
        font-size: 24px !important;
    }
    .question-text,
    .title {
        font-size: 2em;
    }
    .title {
        margin-top: 60px;
        margin-bottom: 25px;
    }
    #questions {
        width: 100%;
        padding: 0 5px;
        text-align: center;
    }
    .range-slider {
        margin: 5vh auto;
        width: 100%;
    }
    .question {
        width: 85%;
    }
    .rs-range {
        margin-top: 30px;
        width: 100%;
    }
    .rs-label {
        width: 50px;
        height: 50px;
        line-height: 45px;
        margin-top: 0;
        margin-left: -5px;
        font-size: 1.7em;
        text-align: center;
    }
    #wheel {
        text-align: center;
        width: 100%;
    }
    .wheel-outside {
        border: none;
    }
    .wheel-outside,
    .wheel-inside,
    .circle-figure {
        height: 300px;
        width: 100%;
    }
    .center {
        width: 5px;
        height: 5px;
        top: 0;
        left: 15px;
    }
    .stroke-container {
        width: 5px;
        height: auto;
    }
    .stroke {
        width: 1px;
        height: 6.8px;
    }
    .dot {
        width: 5px;
        height: 5px;
    }
    .stroke-1 {
        top: -60px;
        left: 10px;
    }
    .stroke-2 {
        top: -30px;
        left: 55px;
    }
    .stroke-3 {
        top: 30px;
        left: 50px;
    }
    .stroke-4 {
        top: 60px;
        left: -5px;
    }
    .stroke-5 {
        top: 30px;
        left: -60px;
    }
    .stroke-6 {
        top: -30px;
        left: -65px;
    }
}
@media all and (min-width: 375px) and (max-width: 768px) {
    .logo-mobile {
        width: 120px;
        display: block;
    }
    #logo-bottom {
        display: none !important;
    }
    .h4,
    h4 {
        font-size: 1.6rem !important;
    }
    .h3,
    h3 {
        font-size: 1.8rem !important;
    }
    .h3 {
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 0 10px;
    }
    #logo {
        left: auto;
        right: auto;
    }
    .title {
        margin-top: 60px;
        margin-bottom: 25px;
    }
    .question-text,
    .title {
        font-size: 2em;
    }
    #questions {
        width: 100%;
        padding: 0 5px;
        text-align: center;
    }
    .range-slider {
        margin: 5vh auto;
        width: 100%;
    }
    .question {
        width: 85%;
    }
    .rs-range {
        margin-top: 30px;
        width: 100%;
    }
    .rs-label {
        width: 50px;
        height: 50px;
        line-height: 45px;
        margin-top: 0;
        margin-left: -5px;
        font-size: 1.7em;
        text-align: center;
    }
    #wheel {
        text-align: center;
        width: 100%;
    }
    .wheel-outside {
        border: none;
    }
    .wheel-outside,
    .wheel-inside,
    .circle-figure {
        width: 100%;
        height: 375px;
    }
    .stroke-container {
        width: 5px;
        height: 145px;
    }
    .stroke {
        width: 1px;
        height: 10px;
    }
    .dot {
        width: 7px;
        height: 7px;
    }
    .stroke-1 {
        top: -75px;
        left: 10px;
    }
    .stroke-2 {
        top: -40px;
        left: 70px;
    }
    .stroke-3 {
        top: 35px;
        left: 65px;
    }
    .stroke-4 {
        top: 75px;
        left: -5px;
    }
    .stroke-5 {
        top: 35px;
        left: -75px;
    }
    .stroke-6 {
        top: -40px;
        left: -80px;
    }
} */
