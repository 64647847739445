.color {
    animation: 0.5s linear 1s infinite alternate slidein;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        //background: #EE749E;
        width: 100%;
    height: 100%;

}

@keyframes slidein {
    0% {
        transform: scale(0.7)
    }
    100% {
        transform: scale(1)
    }
    0% {
        transform: scale(0.7)
    }
}


.pulse {
    &:hover {
        animation: pulse 2s infinite;
    }
}

@keyframes pulse {
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    0% {
        opacity: 1;
    }
}
