.page_steps {
    padding: 36px 64px;
    height: 100%;
    box-sizing: border-box;
    min-height: 100vh;
}
.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-bottom: 95px;
    .head_block {
        height: 80px;
        padding: 0 24px;
        border-radius: 40px;
        background: rgba(238, 116, 158, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            color: #912f40;
            font-size: 28px;
            font-weight: bold;
            line-height: 34px;
        }
        img {
            background-size: 100% 100%;
            height: 32px;
            width: 32px;
            margin-right: 8px;
        }
    }
}
.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.btn {
    margin-top: 80px;
    height: 128px;
    width: 128px;
    //box-shadow: 0 8px 8px -4px rgba(145,47,64,0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    line-height: 47px;
    text-decoration: none;
    box-shadow: 0 0 65px 65px #ee749e inset, 0 0 0 0 #ee749e;
    cursor: pointer;

    &:hover {
        box-shadow: 0 0 10px 0 #ee749e inset, 0 0 15px 6px #ee749e;
        color: #ee749e;
    }
}

.rangeslider__handle {
    &:after {
        display: none;
    }
}
.rangeslider {
    border-radius: 2px;
    height: 4px;
    background: rgba(238, 116, 158, 0.3);
    position: relative;
}
.rangeslider-horizontal .rangeslider__fill {
    background: transparent;
    box-shadow: none;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 48px;
    height: 48px;
    box-shadow: 0 8px 8px -4px rgba(145, 47, 64, 0.25);
    transition: 0.2s ease-in-out;
    &:hover {
        background: #ee749e;
    }
    &:active {
        background: #ee749e;
    }
}
.rangeslider__handle-tooltip {
    height: 80px !important;
    width: 72px !important;
    background-color: transparent !important;
    background: url("../../assets/image/slider.svg"), transparent no-repeat;
    display: flex !important;
    justify-content: center;
    align-items: center;
    transform: translate3d(-50%, -40%, 0) !important;

    span {
        color: #ffffff;
        font-size: 40px;
        font-weight: bold;
        line-height: 47px;
        padding-bottom: 10px;
        margin: 0;
    }
    &:after {
        display: none;
    }
}
.range {
    width: 588px;
}
.page_steps {
    .title {
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        margin-bottom: 80px;
    }
    .descriptions {
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        margin-bottom: 94px;
    }
}

@media only screen and (max-width: 1024px) {
    .page_steps {
        padding: 36px 48px;
        .title {
            font-size: 32px;
            line-height: 38px;
        }
        .descriptions {
            font-size: 32px;
            line-height: 38px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .page_steps {
        padding: 18px 16px;
        .title {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 48px;
        }
        .descriptions {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 64px;
        }
    }
    .head {
        height: 40px;
        margin-bottom: 91px;
        > a {
            img {
                height: 29px;
                width: 80px;
                background-size: 100% 100%;
            }
        }
        .head_block {
            height: 40px;
            img {
                height: 16px;
                width: 16px;
                background-size: 100% 100%;
            }
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
    .btn {
        margin-top: 64px;
        height: 64px;
        width: 64px;
        font-size: 20px;
        line-height: 24px;
    }
    .range {
        width: 282px;
    }

    .rangeslider__handle {
        &:after {
            display: none;
        }
    }
    .rangeslider {
        height: 2px;
    }

    .rangeslider-horizontal .rangeslider__handle {
        width: 24px;
        height: 24px;
    }
    .rangeslider__handle-tooltip {
        height: 40px !important;
        width: 36px !important;
        background-size: 100% 100%;
        transform: translate3d(-50%, 25%, 0) !important;
        span {
            font-size: 20px;
            line-height: 24px;
        }
    }
}
