@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz:wght@200;300;400;500;600;700&display=swap);
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 100%; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block; }

b,
strong {
  font-weight: bold; }

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic; }

li {
  display: list-item;
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

th,
td,
caption {
  font-weight: normal;
  vertical-align: top;
  text-align: left; }

q {
  quotes: none; }

q:before,
q:after {
  content: "";
  content: none; }

sub,
sup,
small {
  font-size: 75%; }

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

svg {
  overflow: hidden; }

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none; }

.clear:after,
.clear:before {
  clear: both;
  display: block;
  zoom: 1;
  content: ""; }

body {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  font-weight: 400;
  color: #912F40;
  overflow: auto;
  box-sizing: border-box;
  -webkit-user-select: none;
          user-select: none; }
  body::-webkit-scrollbar {
    width: 10px;
    height: 10px; }
  body::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    background: #ffffff; }
  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ee749e; }
  body::-webkit-scrollbar-thumb:window-inactive {
    background: #ee749e; }

.bg {
  background: url(/static/media/background.bbc2d5ad.png), no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  z-index: -1; }

.page {
  background: url(/static/media/background.bbc2d5ad.png), no-repeat;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  z-index: -1; }

a, button {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  transition: .2s ease-in-out; }

.color {
  -webkit-animation: 0.5s linear 1s infinite alternate slidein;
          animation: 0.5s linear 1s infinite alternate slidein;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@-webkit-keyframes slidein {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

@keyframes slidein {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

.pulse:hover {
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  0% {
    opacity: 1; } }

@keyframes pulse {
  100% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  0% {
    opacity: 1; } }

.page-animation-enter {
  opacity: 0.01; }

.page-animation-enter.page-animation-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in; }

.page-animation-leave {
  opacity: 1; }

.page-animation-leave.page-animation-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in; }

.page-animation-appear {
  opacity: 0.01; }

.page-animation-appear.page-animation-appear-active {
  opacity: 1;
  transition: all 1s ease; }

.loader_page {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0; }
  .loader_page .head {
    padding: 36px 64px; }
  .loader_page .shadow {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .loader_page .wrapper {
    overflow: hidden;
    position: fixed;
    left: calc(50% - 96px);
    top: calc(50% - 82px);
    z-index: 1; }
  .loader_page .loader_img {
    width: 192px;
    height: 164px; }

@media only screen and (max-width: 1024px) {
  .loader_page .head {
    padding: 36px 48px; } }

@media only screen and (max-width: 767px) {
  .loader_page .head {
    padding: 18px 16px; }
    .loader_page .head img {
      height: 29px;
      width: 80px;
      background-size: 100% 100%; } }

.page_steps {
  padding: 36px 64px;
  height: 100%;
  box-sizing: border-box;
  min-height: 100vh; }

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  margin-bottom: 95px; }
  .head .head_block {
    height: 80px;
    padding: 0 24px;
    border-radius: 40px;
    background: rgba(238, 116, 158, 0.1);
    display: flex;
    align-items: center;
    justify-content: center; }
    .head .head_block span {
      color: #912f40;
      font-size: 28px;
      font-weight: bold;
      line-height: 34px; }
    .head .head_block img {
      background-size: 100% 100%;
      height: 32px;
      width: 32px;
      margin-right: 8px; }

.content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.btn {
  margin-top: 80px;
  height: 128px;
  width: 128px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  line-height: 47px;
  text-decoration: none;
  box-shadow: 0 0 65px 65px #ee749e inset, 0 0 0 0 #ee749e;
  cursor: pointer; }
  .btn:hover {
    box-shadow: 0 0 10px 0 #ee749e inset, 0 0 15px 6px #ee749e;
    color: #ee749e; }

.rangeslider__handle:after {
  display: none; }

.rangeslider {
  border-radius: 2px;
  height: 4px;
  background: rgba(238, 116, 158, 0.3);
  position: relative; }

.rangeslider-horizontal .rangeslider__fill {
  background: transparent;
  box-shadow: none; }

.rangeslider-horizontal .rangeslider__handle {
  width: 48px;
  height: 48px;
  box-shadow: 0 8px 8px -4px rgba(145, 47, 64, 0.25);
  transition: 0.2s ease-in-out; }
  .rangeslider-horizontal .rangeslider__handle:hover {
    background: #ee749e; }
  .rangeslider-horizontal .rangeslider__handle:active {
    background: #ee749e; }

.rangeslider__handle-tooltip {
  height: 80px !important;
  width: 72px !important;
  background-color: transparent !important;
  background: url(/static/media/slider.5accebbd.svg), transparent no-repeat;
  display: flex !important;
  justify-content: center;
  align-items: center;
  -webkit-transform: translate3d(-50%, -40%, 0) !important;
          transform: translate3d(-50%, -40%, 0) !important; }
  .rangeslider__handle-tooltip span {
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    line-height: 47px;
    padding-bottom: 10px;
    margin: 0; }
  .rangeslider__handle-tooltip:after {
    display: none; }

.range {
  width: 588px; }

.page_steps .title {
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  margin-bottom: 80px; }

.page_steps .descriptions {
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  margin-bottom: 94px; }

@media only screen and (max-width: 1024px) {
  .page_steps {
    padding: 36px 48px; }
    .page_steps .title {
      font-size: 32px;
      line-height: 38px; }
    .page_steps .descriptions {
      font-size: 32px;
      line-height: 38px; } }

@media only screen and (max-width: 767px) {
  .page_steps {
    padding: 18px 16px; }
    .page_steps .title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 48px; }
    .page_steps .descriptions {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 64px; }
  .head {
    height: 40px;
    margin-bottom: 91px; }
    .head > a img {
      height: 29px;
      width: 80px;
      background-size: 100% 100%; }
    .head .head_block {
      height: 40px; }
      .head .head_block img {
        height: 16px;
        width: 16px;
        background-size: 100% 100%; }
      .head .head_block span {
        font-size: 14px;
        line-height: 16px; }
  .btn {
    margin-top: 64px;
    height: 64px;
    width: 64px;
    font-size: 20px;
    line-height: 24px; }
  .range {
    width: 282px; }
  .rangeslider__handle:after {
    display: none; }
  .rangeslider {
    height: 2px; }
  .rangeslider-horizontal .rangeslider__handle {
    width: 24px;
    height: 24px; }
  .rangeslider__handle-tooltip {
    height: 40px !important;
    width: 36px !important;
    background-size: 100% 100%;
    -webkit-transform: translate3d(-50%, 25%, 0) !important;
            transform: translate3d(-50%, 25%, 0) !important; }
    .rangeslider__handle-tooltip span {
      font-size: 20px;
      line-height: 24px; } }

.block-input {
  display: block;
  width: 100%;
  margin-bottom: 16px;
  position: relative; }
  .block-input .error {
    display: none; }
  .block-input.error_border, .block-input.value.error_border {
    position: relative; }
    .block-input.error_border input, .block-input.value.error_border input {
      border: 1px solid #e44b4b !important;
      color: #e44b4b !important; }
    .block-input.error_border .error, .block-input.value.error_border .error {
      display: block;
      color: #e44b4b;
      position: absolute;
      left: 0;
      font-size: 10px;
      line-height: 13px;
      white-space: normal; }
      .block-input.error_border .error > p, .block-input.value.error_border .error > p {
        padding: 0; }
  .block-input input {
    display: block;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    color: #912F40;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    height: 48px;
    border: 1px solid #F9CDD7;
    border-radius: 8px; }
    .block-input input:focus {
      border: 1px solid #912F40; }
    .block-input input::-webkit-input-placeholder {
      color: rgba(145, 47, 64, 0.25); }
    .block-input input::placeholder {
      color: rgba(145, 47, 64, 0.25); }

.main_error {
  color: #ff1919;
  padding-top: 8px;
  left: 0;
  font-size: 14px;
  line-height: 13px;
  display: block;
  text-align: center; }

@media only screen and (max-width: 767px) {
  .block-input {
    margin-bottom: 12px; }
    .block-input input {
      height: 40px;
      padding: 0 12px;
      font-size: 14px;
      line-height: 16px; } }

.mail_page {
  width: 100%;
  top: 0;
  padding-bottom: 30px; }
  .mail_page .head {
    padding: 36px 64px; }
  .mail_page .shadow {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .mail_page .wrapper_form {
    left: calc(50% - 240px);
    padding: 48px 80px;
    box-sizing: border-box;
    width: 480px;
    border-radius: 16px;
    background-color: #FEFEFE;
    margin: 0 auto; }
    .mail_page .wrapper_form .title {
      color: #912F40;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      margin-bottom: 28px; }
    .mail_page .wrapper_form .descriptions {
      color: #912F40;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 32px;
      line-height: 24px;
      text-align: center; }
    .mail_page .wrapper_form form {
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .mail_page .wrapper_form form hr {
        height: 1px;
        width: 100%;
        background: #F9CDD7;
        margin: 16px 0 32px; }
      .mail_page .wrapper_form form .wrapper_btn {
        display: flex;
        align-items: center;
        justify-content: center; }
        .mail_page .wrapper_form form .wrapper_btn button {
          color: #EE749E;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background: transparent;
          cursor: pointer;
          margin: 0 auto; }
        .mail_page .wrapper_form form .wrapper_btn button:disabled {
          -webkit-animation: pulse 0s infinite;
                  animation: pulse 0s infinite;
          opacity: 0.5 !important;
          cursor: inherit;
          transition: 0s ease-in-out; }
      .mail_page .wrapper_form form .block_field p {
        color: #912F40;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
        display: flex; }

@media only screen and (max-width: 1024px) {
  .mail_page .head {
    padding: 36px 48px; } }

@media only screen and (max-width: 767px) {
  .mail_page .head {
    padding: 18px 16px;
    height: 40px; }
    .mail_page .head > a img {
      height: 29px;
      width: 80px;
      background-size: 100% 100%; }
  .mail_page .wrapper_form {
    padding: 32px 16px;
    width: 288px;
    left: calc(50% - 144px); }
    .mail_page .wrapper_form .title {
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px; }
    .mail_page .wrapper_form .descriptions {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 24px; }
    .mail_page .wrapper_form form .block_field p {
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 6px; }
    .mail_page .wrapper_form form hr {
      margin: 12px 0 24px; }
    .mail_page .wrapper_form form .wrapper_btn button {
      font-size: 20px;
      line-height: 24px; } }

.default_dialog_root .paper_custom_dialog {
  border-radius: 16px;
  min-width: 480px;
  max-width: 480px;
  width: 100%;
  box-shadow: none; }

.default_dialog_root .dialog_close_button {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  background: transparent;
  transition: all 0.3s linear; }
  .default_dialog_root .dialog_close_button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

.dialog_wrapper {
  justify-content: center;
  padding: 48px 64px 64px;
  display: flex;
  position: relative;
  background-color: #FEFEFE;
  max-width: 480px;
  overflow: auto; }
  .dialog_wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  .dialog_wrapper::-webkit-scrollbar-track {
    -webkit-border-radius: 2px;
    background: #edf2f8; }
  .dialog_wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: grey; }
  .dialog_wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: grey; }

@media only screen and (max-width: 767px) {
  .default_dialog_root .paper_custom_dialog {
    min-width: 288px !important;
    max-width: 288px !important; }
  .default_dialog_root .dialog_close_button {
    right: 16px;
    top: 16px; }
    .default_dialog_root .dialog_close_button img {
      background-size: 100% 100%;
      width: 12px;
      height: 12px; }
  .dialog_wrapper {
    padding: 32px 0px 48px; } }

.chart_page {
  padding: 36px 64px;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box; }
  .chart_page .head {
    margin: 0; }
  .chart_page .text {
    color: #912f40;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    margin: 16px 0 120px; }
  .chart_page .chart-wrapper {
    position: relative; }
  .chart_page .chart {
    height: 460px;
    width: 460px;
    border-radius: 50%;
    border: 3px solid #f6c9da;
    margin: 0 auto 100px; }
  .chart_page .mob_info_chart {
    display: none;
    margin-top: 88px;
    justify-content: center;
    align-items: center; }
    .chart_page .mob_info_chart > div {
      margin: 0 16px; }
      .chart_page .mob_info_chart > div > div {
        margin-bottom: 6px;
        font-size: 10px;
        line-height: 12px; }
        .chart_page .mob_info_chart > div > div > img {
          margin-right: 3px;
          background-size: 100% 100%;
          width: 12px;
          height: 12px; }
  .chart_page .chart_block_wrapper {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%; }
    .chart_page .chart_block_wrapper .chart_block {
      height: 64px;
      border-radius: 40px;
      padding: 0 20px;
      box-sizing: border-box;
      background-color: rgba(238, 116, 158, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 20px;
      font-weight: bold;
      line-height: 24px; }
      .chart_page .chart_block_wrapper .chart_block img {
        background-size: 100% 100%;
        width: 32px;
        height: 32px;
        margin-right: 6px; }
    .chart_page .chart_block_wrapper > div:nth-child(1) {
      top: -85px;
      left: calc(50% - 63px); }
    .chart_page .chart_block_wrapper > div:nth-child(2) {
      top: 0;
      right: -125px; }
    .chart_page .chart_block_wrapper > div:nth-child(3) {
      top: calc(50% - 32px);
      right: -243px; }
    .chart_page .chart_block_wrapper > div:nth-child(4) {
      top: calc(75% - -32px);
      right: -145px; }
    .chart_page .chart_block_wrapper > div:nth-child(5) {
      bottom: -85px;
      left: calc(50% - 76px); }
    .chart_page .chart_block_wrapper > div:nth-child(6) {
      top: calc(75% - -32px);
      left: -80px; }
    .chart_page .chart_block_wrapper > div:nth-child(7) {
      top: calc(50% - 32px);
      left: -140px; }
    .chart_page .chart_block_wrapper > div:nth-child(8) {
      top: 0;
      left: -100px; }

.dialog .dialog_title {
  display: flex;
  align-items: center;
  color: #912f40;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  justify-content: center;
  margin-bottom: 24px; }

.dialog .dialog_text {
  color: #912f40;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: 300;
  max-width: 320px;
  margin: 0 auto; }
  .dialog .dialog_text a {
    color: #ee749e;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400; }

@media only screen and (max-width: 1400px) {
  .chart_page {
    padding: 36px 48px; }
    .chart_page .text {
      font-size: 32px;
      line-height: 38px; }
    .chart_page .chart {
      height: 345px;
      width: 345px;
      margin: 0 auto 50px; }
    .chart_page .chart_block_wrapper .chart_block {
      font-size: 16px;
      line-height: 18px;
      height: 48px;
      padding: 0 16px; }
      .chart_page .chart_block_wrapper .chart_block img {
        width: 28px;
        height: 28px; }
    .chart_page .chart_block_wrapper > div:nth-child(1) {
      left: calc(50% - 52px);
      top: -66px; }
    .chart_page .chart_block_wrapper > div:nth-child(2) {
      right: -108px; }
    .chart_page .chart_block_wrapper > div:nth-child(3) {
      right: -162px;
      max-width: 145px;
      top: calc(50% - 24px); }
    .chart_page .chart_block_wrapper > div:nth-child(4) {
      top: calc(75% - -24px);
      right: -122px; }
    .chart_page .chart_block_wrapper > div:nth-child(5) {
      left: calc(50% - 55px);
      bottom: -66px; }
    .chart_page .chart_block_wrapper > div:nth-child(6) {
      top: calc(75% - -24px);
      left: -72px; }
    .chart_page .chart_block_wrapper > div:nth-child(7) {
      top: calc(50% - 24px);
      left: -116px; }
    .chart_page .chart_block_wrapper > div:nth-child(8) {
      left: -86px; } }

@media only screen and (max-width: 767px) {
  .chart_page {
    padding: 18px 16px; }
    .chart_page .text {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 72px; }
    .chart_page .chart {
      height: 208px;
      width: 208px;
      margin: 0 auto 30px; }
    .chart_page .chart_block_wrapper .chart_block {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      padding: 0; }
      .chart_page .chart_block_wrapper .chart_block img {
        margin: 0;
        width: 16px;
        height: 16px; }
      .chart_page .chart_block_wrapper .chart_block span {
        display: none; }
    .chart_page .chart_block_wrapper > div:nth-child(1) {
      left: calc(50% - 16px);
      top: -40px; }
    .chart_page .chart_block_wrapper > div:nth-child(2) {
      right: -8px; }
    .chart_page .chart_block_wrapper > div:nth-child(3) {
      right: -40px;
      max-width: 100%;
      top: calc(50% - 16px); }
    .chart_page .chart_block_wrapper > div:nth-child(4) {
      top: calc(75% - -16px);
      right: -8px; }
    .chart_page .chart_block_wrapper > div:nth-child(5) {
      left: calc(50% - 16px);
      bottom: -40px; }
    .chart_page .chart_block_wrapper > div:nth-child(6) {
      top: calc(75% - -16px);
      left: -8px; }
    .chart_page .chart_block_wrapper > div:nth-child(7) {
      left: -40px;
      top: calc(50% - 16px); }
    .chart_page .chart_block_wrapper > div:nth-child(8) {
      left: -8px; }
    .chart_page .mob_info_chart {
      display: flex; }
  .dialog .dialog_title {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px; }
  .dialog .dialog_text {
    max-width: 256px;
    font-size: 14px;
    line-height: 16px; }
    .dialog .dialog_text a {
      font-size: 14px;
      line-height: 16px; } }

.circle-body {
  background-color: #d3bebd; }

.row {
  justify-content: center;
  align-items: center; }

.title {
  margin-bottom: 70px; }

.circle-title {
  font-weight: 600;
  color: #4e1414;
  padding-bottom: 20px; }

#logo {
  width: 30vh;
  position: absolute;
  top: 35px;
  left: 30px; }

.question-text,
.title {
  font-size: 3em;
  color: #4e1414 !important; }

.continue {
  background: transparent !important;
  border-radius: 50%;
  padding: 35px 5px;
  font-size: 2em;
  border: 2px solid #4e1414;
  color: #4e1414;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out; }

.continue:active,
.continue:hover {
  border-color: #4e4e4e;
  color: #4e4e4e;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out; }

#questions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#wheel {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out; }

.wheel-visible {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out; }

.wheel-outside {
  background: transparent;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 10px auto 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.wheel-inside {
  background-image: url(https://circle.blinovskaya.com/img/circle.png);
  width: 590px;
  height: 590px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center; }

.circle-figure {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.center {
  position: relative;
  background: #ee749e;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50px;
  top: 0px;
  left: 21px;
  z-index: 2; }
  @media only screen and (max-width: 1400px) {
    .center {
      width: 9px;
      height: 9px;
      left: 19.5px; } }
  @media only screen and (max-width: 767px) {
    .center {
      width: 5.5px;
      height: 5.5px;
      left: 17.75px; } }

.stroke-container {
  width: 5px;
  height: 230px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  z-index: 1; }
  @media only screen and (max-width: 1400px) {
    .stroke-container {
      width: 5px;
      height: 172.5px; } }
  @media only screen and (max-width: 767px) {
    .stroke-container {
      height: 104px; } }

.stroke {
  background: #f6c9da;
  width: 3px;
  height: 20px;
  display: inline-block; }
  @media only screen and (max-width: 1400px) {
    .stroke {
      width: 2px;
      height: 11px; } }
  @media only screen and (max-width: 767px) {
    .stroke {
      width: 1.5px;
      height: 6.7px; } }

.dot {
  background: #f6c9da;
  width: 7px;
  height: 7px;
  display: inline-block;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative; }
  @media only screen and (max-width: 1400px) {
    .dot {
      width: 6px;
      height: 6px; } }
  @media only screen and (max-width: 767px) {
    .dot {
      width: 3.5px !important;
      height: 3.5px !important; } }

.stroke-1 {
  top: -118px;
  left: 12.7px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-1 {
      top: -89px;
      left: 12.7px; } }
  @media only screen and (max-width: 767px) {
    .stroke-1 {
      top: -53px;
      left: 12.7px; } }

.stroke-2 {
  top: -83px;
  left: 90px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-2 {
      top: -63px;
      left: 71px; } }
  @media only screen and (max-width: 767px) {
    .stroke-2 {
      top: -38px;
      left: 45px; } }

.stroke-3 {
  top: 0px;
  left: 117px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-3 {
      top: 0px;
      left: 92px; } }
  @media only screen and (max-width: 767px) {
    .stroke-3 {
      top: 0px;
      left: 56px; } }

.stroke-4 {
  top: 81px;
  left: 80px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-4 {
      top: 63px;
      left: 60px; } }
  @media only screen and (max-width: 767px) {
    .stroke-4 {
      top: 38px;
      left: 35px; } }

.stroke-5 {
  top: 117px;
  left: -7.3px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-5 {
      top: 87px;
      left: -7.3px; } }
  @media only screen and (max-width: 767px) {
    .stroke-5 {
      top: 54px;
      left: -7.3px; } }

.stroke-6 {
  top: 82px;
  left: -96px;
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-6 {
      top: 63px;
      left: -75px; } }
  @media only screen and (max-width: 767px) {
    .stroke-6 {
      top: 38px;
      left: -51px; } }

.stroke-7 {
  top: 0px;
  left: -133px;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-7 {
      top: 0px;
      left: -104px; } }
  @media only screen and (max-width: 767px) {
    .stroke-7 {
      top: 0px;
      left: -70px; } }

.stroke-8 {
  top: -84px;
  left: -105px;
  transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg); }
  @media only screen and (max-width: 1400px) {
    .stroke-8 {
      top: -63px;
      left: -85px; } }
  @media only screen and (max-width: 767px) {
    .stroke-8 {
      top: -38px;
      left: -61px; } }

.color-stroke--1 {
  background: #ee749e !important; }

.color-stroke--2 {
  background: #ee749e !important; }

.color-stroke--3 {
  background: #ee749e !important; }

.color-stroke--4 {
  background: #ee749e !important; }

.color-stroke--5 {
  background: #ee749e !important; }

.color-stroke--6 {
  background: #ee749e !important; }

.color-stroke--7 {
  background: #ee749e !important; }

.color-stroke--8 {
  background: #ee749e !important; }

.color-dot--1 {
  background: #ee749e !important; }

.color-dot--2 {
  background: #ee749e !important; }

.color-dot--3 {
  background: #ee749e !important; }

.color-dot--4 {
  background: #ee749e !important; }

.color-dot--5 {
  background: #ee749e !important; }

.color-dot--6 {
  background: #ee749e !important; }

.color-dot--7 {
  background: #ee749e !important; }

.color-dot--8 {
  background: #ee749e !important; }

.svg {
  width: 460px;
  height: 460px; }
  @media only screen and (max-width: 767px) {
    .svg {
      width: 208px;
      height: 208px; } }

.svg-1 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-2 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-3 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-4 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-5 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-6 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-7 {
  stroke: #ee749e;
  stroke-width: 0; }

.svg-8 {
  stroke: #ee749e;
  stroke-width: 0; }

.transition-svg-1 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 0.5s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-1 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-1 {
      stroke-width: 1; } }

.transition-svg-2 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-2 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-2 {
      stroke-width: 1; } }

.transition-svg-3 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 1.5s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-3 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-3 {
      stroke-width: 1; } }

.transition-svg-4 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-4 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-4 {
      stroke-width: 1; } }

.transition-svg-5 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 2.5s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-5 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-5 {
      stroke-width: 1; } }

.transition-svg-6 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-6 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-6 {
      stroke-width: 1; } }

.transition-svg-7 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 3.5s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-7 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-7 {
      stroke-width: 1; } }

.transition-svg-8 {
  stroke-width: 3;
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s;
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56) 4s; }
  @media only screen and (max-width: 1400px) {
    .transition-svg-8 {
      stroke-width: 2; } }
  @media only screen and (max-width: 767px) {
    .transition-svg-8 {
      stroke-width: 1; } }

.transition-stroke {
  transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
  -webkit-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
  -moz-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
  -ms-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56);
  -o-transition: all 1s cubic-bezier(0.36, 0, 0.66, -0.56); }

.transition-dot {
  transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -webkit-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -moz-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -ms-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  -o-transition: all 2s cubic-bezier(0.68, -0.6, 0.32, 1.6); }

.highlight {
  border: 2px solid #ff0000 !important; }

:focus {
  outline: none -webkit-focus-ring-color !important; }

.btn:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 transparent !important; }

.svg {
  position: absolute;
  overflow: visible !important; }

.path {
  position: relative; }

/*Range Slider Styles*/
.range-slider {
  margin: 10vh auto; }

.rs-range {
  margin-top: 30px;
  width: 600px;
  -webkit-appearance: none; }

.rs-range:focus {
  outline: none; }

.rs-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: none;
  background: #fff;
  border-radius: 0px;
  border: 0px solid #4e1414; }

.rs-range::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: none;
  background: #fff;
  border-radius: 0px;
  border: 0px solid #4e1414; }

.rs-range::-webkit-slider-thumb {
  box-shadow: none;
  border: 0px solid #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%; }

.rs-range::-moz-range-thumb {
  box-shadow: none;
  border: 0px solid #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -20px; }

.rs-range::-moz-focus-outer {
  border: 0; }

.rs-label {
  position: relative;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  display: block;
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 50%;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  padding-top: 0;
  box-sizing: border-box;
  border: 2px solid #4e1414;
  margin-top: 0;
  margin-left: -10px;
  left: attr(value);
  color: #4e1414;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 2.5em; }

/*Range Slider Styles*/
/* @media all and (max-width: 374px) {
    .h4,
    h4 {
        font-size: 1.6rem !important;
    }
    .h3,
    h3 {
        font-size: 1.8rem !important;
    }
    .h3 {
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 0 10px;
    }
    #logo {
        left: auto;
        right: auto;
    }
    #logo-bottom {
        display: none !important;
    }
    .logo-mobile {
        width: 110px;
        display: block;
    }
    .h2,
    h2 {
        font-size: 24px !important;
    }
    .question-text,
    .title {
        font-size: 2em;
    }
    .title {
        margin-top: 60px;
        margin-bottom: 25px;
    }
    #questions {
        width: 100%;
        padding: 0 5px;
        text-align: center;
    }
    .range-slider {
        margin: 5vh auto;
        width: 100%;
    }
    .question {
        width: 85%;
    }
    .rs-range {
        margin-top: 30px;
        width: 100%;
    }
    .rs-label {
        width: 50px;
        height: 50px;
        line-height: 45px;
        margin-top: 0;
        margin-left: -5px;
        font-size: 1.7em;
        text-align: center;
    }
    #wheel {
        text-align: center;
        width: 100%;
    }
    .wheel-outside {
        border: none;
    }
    .wheel-outside,
    .wheel-inside,
    .circle-figure {
        height: 300px;
        width: 100%;
    }
    .center {
        width: 5px;
        height: 5px;
        top: 0;
        left: 15px;
    }
    .stroke-container {
        width: 5px;
        height: auto;
    }
    .stroke {
        width: 1px;
        height: 6.8px;
    }
    .dot {
        width: 5px;
        height: 5px;
    }
    .stroke-1 {
        top: -60px;
        left: 10px;
    }
    .stroke-2 {
        top: -30px;
        left: 55px;
    }
    .stroke-3 {
        top: 30px;
        left: 50px;
    }
    .stroke-4 {
        top: 60px;
        left: -5px;
    }
    .stroke-5 {
        top: 30px;
        left: -60px;
    }
    .stroke-6 {
        top: -30px;
        left: -65px;
    }
}
@media all and (min-width: 375px) and (max-width: 768px) {
    .logo-mobile {
        width: 120px;
        display: block;
    }
    #logo-bottom {
        display: none !important;
    }
    .h4,
    h4 {
        font-size: 1.6rem !important;
    }
    .h3,
    h3 {
        font-size: 1.8rem !important;
    }
    .h3 {
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 0 10px;
    }
    #logo {
        left: auto;
        right: auto;
    }
    .title {
        margin-top: 60px;
        margin-bottom: 25px;
    }
    .question-text,
    .title {
        font-size: 2em;
    }
    #questions {
        width: 100%;
        padding: 0 5px;
        text-align: center;
    }
    .range-slider {
        margin: 5vh auto;
        width: 100%;
    }
    .question {
        width: 85%;
    }
    .rs-range {
        margin-top: 30px;
        width: 100%;
    }
    .rs-label {
        width: 50px;
        height: 50px;
        line-height: 45px;
        margin-top: 0;
        margin-left: -5px;
        font-size: 1.7em;
        text-align: center;
    }
    #wheel {
        text-align: center;
        width: 100%;
    }
    .wheel-outside {
        border: none;
    }
    .wheel-outside,
    .wheel-inside,
    .circle-figure {
        width: 100%;
        height: 375px;
    }
    .stroke-container {
        width: 5px;
        height: 145px;
    }
    .stroke {
        width: 1px;
        height: 10px;
    }
    .dot {
        width: 7px;
        height: 7px;
    }
    .stroke-1 {
        top: -75px;
        left: 10px;
    }
    .stroke-2 {
        top: -40px;
        left: 70px;
    }
    .stroke-3 {
        top: 35px;
        left: 65px;
    }
    .stroke-4 {
        top: 75px;
        left: -5px;
    }
    .stroke-5 {
        top: 35px;
        left: -75px;
    }
    .stroke-6 {
        top: -40px;
        left: -80px;
    }
} */

