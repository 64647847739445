.block-input {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    .error {
        display: none;
    }
    &.error_border,
    &.value.error_border {
        position: relative;
        input {
            border: 1px solid #e44b4b !important;
            color: #e44b4b !important;
        }
        .error {
            display: block;
            color: #e44b4b;
            position: absolute;
            left: 0;
            font-size: 10px;
            line-height: 13px;
            white-space: normal;
            > p {
                padding: 0;
            }
        }
    }
    input {
        display: block;
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;
        color: #912F40;
        font-family: 'Yanone Kaffeesatz', sans-serif;
        height: 48px;
        border: 1px solid #F9CDD7;
        border-radius: 8px;
        &:focus {
            border: 1px solid #912F40;
        }
        &::placeholder {
            color: rgba(145, 47, 64, 0.25);
        }
    }
}
.main_error {
    color: #ff1919;
    padding-top: 8px;
    left: 0;
    font-size: 14px;
    line-height: 13px;
    display: block;
    text-align: center;
}
@media only screen and (max-width: 767px) {
    .block-input{
        margin-bottom: 12px;
        input{
            height: 40px;
            padding: 0 12px;
            font-size: 14px;
            line-height: 16px;
        }
    }
}
