body {
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-weight: 400;
    color: #912F40;
    overflow: auto;
    box-sizing: border-box;
    user-select: none;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius:10px;
        border-radius: 10px;
        background: #ee749e ;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: #ee749e ;
    }
}
.bg{
    background: url("../assets/image/background.png"), no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
}
.page{
    background: url("../assets/image/background.png"), no-repeat;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
}

a, button{
    font-family: 'Yanone Kaffeesatz', sans-serif;
    transition: .2s ease-in-out;
}
