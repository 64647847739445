.chart_page {
    padding: 36px 64px;
    height: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    .head {
        margin: 0;
    }
    .text {
        color: #912f40;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        margin: 16px 0 120px;
    }
    .chart-wrapper {
        position: relative;
    }
    .chart {
        height: 460px;
        width: 460px;
        border-radius: 50%;
        border: 3px solid #f6c9da;
        margin: 0 auto 100px;

    }
    .mob_info_chart {
        display: none;
        margin-top: 88px;
        justify-content: center;
        align-items: center;
        > div {
            margin: 0 16px;
            > div {
                margin-bottom: 6px;
                font-size: 10px;
                line-height: 12px;
                > img {
                    margin-right: 3px;
                    background-size: 100% 100%;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    .chart_block_wrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        .chart_block {
            height: 64px;
            border-radius: 40px;
            padding: 0 20px;
            box-sizing: border-box;
            background-color: rgba(238, 116, 158, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            img {
                background-size: 100% 100%;
                width: 32px;
                height: 32px;
                margin-right: 6px;
            }
        }
        & > div:nth-child(1) {
            top: -85px;
            left: calc(50% - 63px);
        }

        & > div:nth-child(2) {
            top: 0;
            right: -125px;
        }

        & > div:nth-child(3) {
            top: calc(50% - 32px);
            right: -243px;
        }

        & > div:nth-child(4) {
            top: calc(75% - -32px);
            right: -145px;
        }

        & > div:nth-child(5) {
            bottom: -85px;
            left: calc(50% - 76px);
        }

        & > div:nth-child(6) {
            top: calc(75% - -32px);
            left: -80px;
        }

        & > div:nth-child(7) {
            top: calc(50% - 32px);
            left: -140px;
        }

        & > div:nth-child(8) {
            top: 0;
            left: -100px;
        }
    }
}

.dialog {
    .dialog_title {
        display: flex;
        align-items: center;
        color: #912f40;
        font-size: 40px;
        line-height: 47px;
        text-align: center;
        justify-content: center;
        margin-bottom: 24px;
    }
    .dialog_text {
        color: #912f40;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        font-weight: 300;
        max-width: 320px;
        margin: 0 auto;
        a {
            color: #ee749e;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .chart_page {
        padding: 36px 48px;
        .text {
            font-size: 32px;
            line-height: 38px;
        }
        .chart {
            height: 345px;
            width: 345px;
            margin: 0 auto 50px;
        }
        .chart_block_wrapper {
            .chart_block {
                font-size: 16px;
                line-height: 18px;
                height: 48px;
                padding: 0 16px;
                img {
                    width: 28px;
                    height: 28px;
                }
            }
            > div:nth-child(1) {
                left: calc(50% - 52px);
                top: -66px;
            }
            > div:nth-child(2) {
                right: -108px;
            }
            > div:nth-child(3) {
                right: -162px;
                max-width: 145px;
                top: calc(50% - 24px);
            }
            > div:nth-child(4) {
                top: calc(75% - -24px);
                right: -122px;
            }
            > div:nth-child(5) {
                left: calc(50% - 55px);
                bottom: -66px;
            }
            > div:nth-child(6) {
                top: calc(75% - -24px);
                left: -72px;
            }
            > div:nth-child(7) {
                top: calc(50% - 24px);
                left: -116px;
            }
            > div:nth-child(8) {
                left: -86px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .chart_page {
        padding: 18px 16px;
        .text {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 72px;
        }
        .chart {
            height: 208px;
            width: 208px;
            margin: 0 auto 30px;
        }
        .chart_block_wrapper {
            .chart_block {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                padding: 0;
                img {
                    margin: 0;
                    width: 16px;
                    height: 16px;
                }
                span {
                    display: none;
                }
            }
            > div:nth-child(1) {
                left: calc(50% - 16px);
                top: -40px;
            }
            > div:nth-child(2) {
                right: -8px;
            }
            > div:nth-child(3) {
                right: -40px;
                max-width: 100%;
                top: calc(50% - 16px);
            }
            > div:nth-child(4) {
                top: calc(75% - -16px);
                right: -8px;
            }
            > div:nth-child(5) {
                left: calc(50% - 16px);
                bottom: -40px;
            }
            > div:nth-child(6) {
                top: calc(75% - -16px);
                left: -8px;
            }
            > div:nth-child(7) {
                left: -40px;
                top: calc(50% - 16px);
            }
            > div:nth-child(8) {
                left: -8px;
            }
        }
        .mob_info_chart {
            display: flex;
        }
    }

    .dialog {
        .dialog_title {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 16px;
        }
        .dialog_text {
            max-width: 256px;
            font-size: 14px;
            line-height: 16px;
            a {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}
