.default_dialog_root {
    .paper_custom_dialog {
        border-radius: 16px;
        min-width: 480px;
        max-width: 480px;
        width: 100%;
        box-shadow: none;
    }
    .dialog_close_button {
        cursor: pointer;
        position: absolute;
        right: 24px;
        top: 24px;
        background: transparent;
        -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
        &:hover {
            transform: scale(1.1);
        }
    }
}

.dialog_wrapper {
    justify-content: center;
    padding: 48px 64px 64px;
    display: flex;
    position: relative;
    background-color: #FEFEFE;
    max-width: 480px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        -webkit-border-radius: 2px;
        background: #edf2f8;
    }
    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 5px;
        border-radius: 5px;
        background: grey;
    }
    &::-webkit-scrollbar-thumb:window-inactive {
        background: grey;
    }
}

@media only screen and (max-width: 767px) {
    .default_dialog_root .paper_custom_dialog {
        min-width: 288px !important;
        max-width: 288px !important;
    }
    .default_dialog_root {
        .dialog_close_button {
            right: 16px;
            top: 16px;
            img {
                background-size: 100% 100%;
                width: 12px;
                height: 12px;
            }
        }
    }
    .dialog_wrapper {
        padding: 32px 0px 48px;
    }

}
