.mail_page{
  width: 100%;
  top: 0;
  padding-bottom: 30px;
  .head{
    padding: 36px 64px;
  }
  .shadow{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .wrapper_form{
    left: calc(50% - 240px);
    padding: 48px 80px;
    box-sizing: border-box;
    width: 480px;
    border-radius: 16px;
    background-color: #FEFEFE;
    margin: 0 auto ;
    .title{
      color: #912F40;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      margin-bottom: 28px;
    }
    .descriptions{
      color: #912F40;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 32px;
      line-height: 24px;
      text-align: center;
    }
    form{
      display: flex;
      justify-content: center;
      flex-direction: column;
      hr{
        height: 1px;
        width: 100%;
        background:  #F9CDD7;
        margin: 16px 0 32px;

      }
      .wrapper_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        button{
          color: #EE749E;
          font-size: 32px;
          font-weight: bold;
          text-align: center;
          background: transparent;
          cursor: pointer;
          margin: 0 auto;
        }
        button:disabled{
          animation: pulse 0s infinite;
          opacity: 0.5!important;
          cursor: inherit;
          transition: 0s ease-in-out;
        }
      }
      .block_field{
        p{
          color: #912F40;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
          display: flex;
        }
      }

    }
  }
}
@media only screen and (max-width: 1024px) {
  .mail_page {
    .head{
      padding: 36px 48px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .mail_page{
    .head{
      padding: 18px 16px;
      height: 40px;
      >a{
        img{
          height: 29px;
          width: 80px;
          background-size: 100% 100%;
        }
      }
    }
    .wrapper_form{
      padding: 32px 16px;
      width: 288px;
      left: calc(50% - 144px);
      .title{
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
      }
      .descriptions{
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 24px;
      }
      form{

        .block_field{
          p{
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 6px;
          }

        }
        hr{
          margin: 12px 0 24px;
        }
        .wrapper_btn{
          button{
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

    }
  }
}